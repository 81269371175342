<template>
  <div class="position-relative">
    <Grid
      ref="gridAdmin"
      :key="gridKey"
      :widgets="widgets"
      :disable="!editable"
      :class="{ disabled: !editable }"
    />
    <div id="kt_widgets" class="offcanvas offcanvas-right p-10">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0">{{ $t("widgets.widgets") }}</h3>
        <button class="btn btn-clean" @click="widgetsOffcanvas.hide()">
          <i class="fal fa-xmark p-0" />
        </button>
      </div>
      <div class="separator separator-solid my-3"></div>
      <div
        v-for="(widget, i) in systemWidgets"
        :key="i"
        class="mb-3 w-100"
        @click="addWidget(widget)"
      >
        <BasePreview :data="widget" class="cursor-pointer" />
      </div>
    </div>
    <div class="buttons-with-shadows position-fixed bottom-0 right-0 mb-12">
      <v-speed-dial
        v-if="!editable"
        bottom
        right
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template #activator>
          <button
            class="btn btn-primary btn-icon btn-lg rounded-circle p-10 elevation-8"
          >
            <i class="fal fa-grid-2-plus"></i>
          </button>
        </template>
        <button
          v-b-tooltip.left.noninteractive="$t('widgets.reloadWidgets')"
          class="btn btn-primary btn-icon elevation-8"
          @click="reloadWidgets"
        >
          <i class="fal fa-arrow-rotate-right" />
        </button>
        <button
          id="kt_widgets_toggle"
          ref="kt_widgets_toggle"
          v-b-tooltip.left.noninteractive="$t('widgets.add')"
          class="btn btn-primary btn-icon elevation-8"
          @click="openAside"
        >
          <i class="fal fa-plus" />
        </button>
        <button
          v-b-tooltip.left.noninteractive="$t('widgets.enableEdit')"
          class="btn btn-primary btn-icon elevation-8"
          @click="toggleEdit()"
        >
          <i class="fal fa-pen" />
        </button>
      </v-speed-dial>
      <button
        v-else
        v-b-tooltip.left.noninteractive="$t('widgets.disableEdit')"
        class="btn btn-primary btn-icon btn-lg rounded-circle p-10 elevation-8 mr-4 mb-4"
        @click="toggleEdit()"
      >
        <i class="fal fa-xmark"></i>
      </button>
    </div>
  </div>
</template>

<script>
import "gridstack/dist/gridstack.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTOffcanvas from "@/assets/js/components/offcanvas";
import Grid from "@/components/Admins/Welcome/Grid";
import { bus } from "@/main";
import AdminWidgets from "@/components/Admins/Welcome/adminWidgets";
import AdminWidgetConfig from "@/components/Admins/Welcome/adminWidgetConfig";

import $ from "jquery";

// Components
import BasePreview from "@/components/Admins/Welcome/Widgets/BasePreview";

export default {
  name: "AdminWelcome",
  components: {
    Grid,
    BasePreview
  },
  data() {
    return {
      widgetsOffcanvas: null,
      widgets: [],
      systemWidgets: [],
      gridKey: 1,
      ids: 4,
      editable: false
    };
  },
  mounted() {
    this.initWidgets();
    this.$forceUpdate();
    this.initWidgetsOffcanvas();
    this.loadSystemWidgets();
    this.loadUserWidgets();

    let me = this;
    bus
      .$on("removeWidget", payload => {
        let widget = me.widgets.find(el => el.id === payload.id);
        let index = me.widgets.indexOf(widget);
        if (index < 0) return;
        me.widgets.splice(index, 1);
        me.gridKey++;
        me.removeWidget(payload.id);
      })
      .$on("updateWidget", payload => {
        let dataWidget = this.widgets.find(w => w.id === payload.id);
        let $gridItem = $(
          ".grid-stack-item[data-gs-id='" + payload.id + "']"
        ).first();
        dataWidget.x = parseInt($gridItem.attr("data-gs-x"));
        dataWidget.y = parseInt($gridItem.attr("data-gs-y"));
        dataWidget.height = parseInt($gridItem.attr("data-gs-height"));
        dataWidget.width = parseInt($gridItem.attr("data-gs-width"));
        me.updateWidget(payload.id, dataWidget);
      })
      .$on("updateWidgetsGrid", function () {
        me.gridKey++;
      });

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.dashboard"),
        route: "/admin/welcome"
      }
    ]);

    // Loose focus on speed dial buttons to keep shadow, because metronics did a btn:focus { box-shadow: none !important } on it...
    $(".buttons-with-shadows .btn").focus(function () {
      this.blur();
    });
  },
  beforeDestroy() {
    bus.$off("removeWidget").$off("updateWidget").$off("updateWidgetsGrid");
  },
  methods: {
    initWidgets() {
      this.widgets = [
        { name: "welcome", x: 0, y: 0, width: 8, height: 2, id: 99 }
      ];
    },
    initWidgetsOffcanvas() {
      this.widgetsOffcanvas = new KTOffcanvas("kt_widgets", {
        baseClass: "offcanvas",
        overlay: true
      });
    },
    loadSystemWidgets() {
      // Load all available widgets
      AdminWidgets.getAll()
        .then(response => {
          this.systemWidgets = response.data.data;
        })
        .catch(error => {
          if (typeof error.response !== "undefined") {
            this.$swal.fire({
              icon: "error",
              title: this.$t("general.caution"),
              text: error.response.data.message
            });
          }
        });
    },
    loadUserWidgets() {
      // Load users widgets
      AdminWidgetConfig.getAll()
        .then(response => {
          let userWidgets = response.data.data;
          userWidgets.forEach(w => this.pushWidget(w));
          this.gridKey++;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    addWidget(widget) {
      const data = this.prepareStoreData(widget);
      AdminWidgetConfig.store(data)
        .then(response => {
          let result = response.data;
          this.pushWidget(result);
          this.toggleEdit(true);
          this.gridKey++;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response.data.message
          });
        });
    },
    updateWidget(id, widget) {
      const data = this.prepareUpdateData(widget);
      AdminWidgetConfig.update(id, data)
        .then(response => {
          widget = this.prepareWidget(response.data);
          let target = this.widgets.find(w => w.id === widget.id);
          let index = this.widgets.indexOf(target);
          this.widgets[index] = widget;
          this.gridKey++;
          bus.$emit("updateWidgetDone", { id: widget.id, success: true });
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response.data.message
          });
          bus.$emit("updateWidgetDone", { id: widget.id, success: false });
        });
    },
    removeWidget(id) {
      AdminWidgetConfig.delete(id).catch(error => {
        this.$swal.fire({
          icon: "error",
          title: this.$t("general.caution"),
          text: error.response.data.message
        });
      });
    },
    pushWidget(data) {
      let newWidget = this.prepareWidget(data);
      this.widgets.push(newWidget);
      return newWidget;
    },
    prepareWidget(data) {
      let widget = {
        id: data.id,
        name: data.widget.name,
        width: data.width,
        height: data.height,
        x: data.x,
        y: data.y,
        config: data.config,
        data: data.data,
        widget: data.widget
      };
      widget = this.applyConfig(widget);
      return widget;
    },
    prepareStoreData(widget) {
      return {
        widget_id: widget.id,
        x: widget.x ?? 0,
        y: widget.y ?? 99,
        width: widget.width,
        height: widget.height,
        config: widget.config ? this.convertConfig(widget.config) : []
      };
    },
    prepareUpdateData(widgetConfig) {
      return {
        widget_id: widgetConfig.widget.id,
        x: widgetConfig.x ?? 0,
        y: widgetConfig.y ?? 99,
        width: widgetConfig.width,
        height: widgetConfig.height,
        config: widgetConfig.config
          ? this.convertConfig(widgetConfig.config)
          : []
      };
    },
    convertConfig(config) {
      let converted = {};
      config.forEach(field => {
        converted[field.name] = field.value;
      });
      return JSON.parse(JSON.stringify(converted));
    },
    applyConfig(widget) {
      let baseConfig = widget.widget.config.slice();
      let config = JSON.parse(JSON.stringify(widget.config));
      widget.config = [];
      baseConfig.forEach(bc => {
        if (config[bc.name]) {
          bc.value = config[bc.name];
        }
        widget.config.push(bc);
      });
      return widget;
    },
    toggleEdit(state = undefined) {
      this.editable = state !== undefined ? state : !this.editable;
      this.gridKey++;
      this.$nextTick().then(() => {
        $(".buttons-with-shadows .btn").focus(function () {
          this.blur();
        });
      });
    },
    openAside() {
      this.widgetsOffcanvas.show();
    },
    reloadWidgets() {
      this.initWidgets();
      this.loadSystemWidgets();
      this.loadUserWidgets();
    }
  }
};
</script>

<style lang="scss">
.v-speed-dial {
  .v-speed-dial__list {
    .btn {
      margin: 6px;
    }
  }
}
.grid-stack {
  .card {
    cursor: move;
    .disabled & {
      cursor: default;
    }
  }
}
</style>
