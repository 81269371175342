import ApiService from "@/core/services/api.service";
var url = process.env.VUE_APP_API_ADMIN;
ApiService.setHeader();

class AdminWidgetConfig {
  get(id) {
    return ApiService.get(url, "widget_config/" + id);
  }
  getAll() {
    let options = "?page=1&perPage=99";
    return ApiService.get(url, "widget_config" + options);
  }
  store(data) {
    return ApiService.post(url + "/widget_config", data);
  }
  update(id, data) {
    return ApiService.put(url + "/widget_config/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/widget_config/" + id);
  }
  getData(params) {
    return ApiService.post(url + "/widget_config/data", params);
  }
}
export default new AdminWidgetConfig();
