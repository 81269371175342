<template>
  <div ref="wrapper">
    <div class="row align-items-center">
      <div class="col">
        <label>
          {{ $t(`${snippetPrefix}.${field.label ? field.label : field.name}`) }}
          <span v-if="field.required" class="red--text">*</span>
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1">
        <div v-if="types.text.includes(field.type)">
          <div class="input-group">
            <input
              v-model="field.value"
              type="text"
              class="form-control"
              :placeholder="placeholder"
              :disabled="disabled"
            />
          </div>
        </div>
        <div v-else-if="types.number.includes(field.type)">
          <div class="input-group">
            <input
              v-model="field.value"
              type="number"
              class="form-control"
              :placeholder="placeholder"
              :disabled="disabled"
            />
          </div>
        </div>
        <div v-else-if="field.type === 'password'">
          <div class="input-group">
            <input
              v-model="field.value"
              :type="[showPassword ? 'text' : 'password']"
              class="form-control"
              :disabled="disabled"
            />
            <div
              class="input-group-append"
              @click="showPassword = !showPassword"
            >
              <span class="input-group-text">
                <i
                  class="fal icon-lg cursor-pointer"
                  :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="types.select.includes(field.type)">
          <v-select
            v-model="field.value"
            class="form-control"
            :items="field.options"
            :item-text="item => selectText(item)"
            item-value="value"
            :menu-props="{ offsetY: true }"
            :disabled="disabled"
            :placeholder="placeholder"
            :multiple="field.type === 'multiselect'"
          />
        </div>
        <div v-else-if="types.checkbox.includes(field.type)">
          <span class="switch">
            <label>
              <input
                v-model="field.value"
                type="checkbox"
                :checked="!!field.default"
                :disabled="disabled"
              />
              <span></span>
            </label>
          </span>
        </div>
        <div v-else-if="field.type === 'file' && Array.isArray(field.value)">
          <b-form-file v-model="field.value" />
        </div>
        <div v-else-if="field.type === 'textarea'">
          <textarea
            v-model="field.value"
            class="form-control"
            :disabled="disabled"
            :placeholder="placeholder"
          />
        </div>
        <div v-else-if="field.type === 'editor'">
          <quill-editor
            ref="myTextEditor"
            class="editor"
            :options="editorOptions"
            :value="field.value"
            :disabled="disabled"
            :placeholder="placeholder"
            @change="onEditorChange"
          />
        </div>
        <div v-else-if="field.type === 'json'">
          <prism-editor
            v-model="field.value"
            class="jsonEditor"
            style="min-height: 45vh !important; max-width: 100%"
            :highlight="highlighterJson"
            line-numbers
          />
          <div
            v-if="!validJson"
            class="mt-7 alert alert-danger"
            v-html="$t('processManager.invalidJson')"
          ></div>
        </div>
        <div v-else-if="field.type === 'date'">
          <b-input v-model="field.value" type="date"></b-input>
        </div>
        <div v-else-if="field.type === 'switch'">
          <span class="switch switch-sm">
            <label>
              <input v-model="field.value" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
        <div v-else-if="field.type === 'inputSelect'">
          <b-form-select
            v-model="field.value"
            :options="inputSelectOptions"
            class="mb-3"
            value-field="value"
            text-field="label"
            disabled-field="notEnabled"
            :multiple="field.multi"
          ></b-form-select>
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
      <div
        v-if="field.help"
        class="ml-1"
        :class="[field.type === 'select' ? 'mt-3' : 'mt-2']"
      >
        <button
          v-b-tooltip.bottom.noninteractive="
            $t(`${snippetPrefix}.${field.help}`)
          "
        >
          <i class="fal fa-circle-question" dark />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-json";

export default {
  components: { PrismEditor },
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    snippetPrefix: {
      type: String,
      default: ""
    },
    transformer: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      showPassword: false,
      x: 0,
      y: 0,
      width: 100,
      editorOptions: {
        theme: "snow"
      },
      types: {
        text: ["text", "string"],
        number: ["number", "int", "integer", "float"],
        select: ["select", "multiselect"],
        checkbox: ["checkbox", "check"]
      }
    };
  },
  computed: {
    placeholder: function () {
      return this.field.placeholder
        ? this.$t(`${this.snippetPrefix}.${this.field.placeholder}`)
        : "";
    },
    validJson() {
      if (this.field.value.length > 1) {
        try {
          JSON.parse(this.field.value);
        } catch (e) {
          return false;
        }
      }
      return true;
    },
    inputSelectOptions() {
      //TODO: add "output.collection" to the select only when the target is collection

      let returnData = [];
      if (this.field.type === "inputSelect") {
        let availableInputs = this.transformer.config.input;
        availableInputs.forEach(field => {
          const tmpObj = {
            value: field,
            label: field
          };
          returnData.push(tmpObj);
        });
      }
      return returnData;
    }
  },
  methods: {
    checkType(item) {
      if (this.type === "number") {
        return !isNaN(item.value);
      }
      return this.type === "text";
    },
    onEditorChange(value) {
      this.field.value = value.html;
    },
    selectChange(value) {
      this.value = value;
      setTimeout(this.checkRequiredFields, 100);
    },
    setValue(object, key, value) {
      this.$set(object, key, value);
      this.reRenderList();
    },
    selectText(item) {
      if (this.field.optionsClass) {
        return item.label;
      } else if (item.label) {
        return this.$t(`${this.snippetPrefix}.${item.label}`);
      } else {
        return this.$t(`${this.snippetPrefix}.${item.value}`);
      }
    },
    highlighterJson(code) {
      return highlight(code, languages.json);
    }
  }
};
</script>

<style>
.modal-body.add-config-value {
  padding: 0;
}
</style>

<style lang="scss">
.jsonEditor {
  background: #2d2d2d;
  color: #ccc;
  min-height: 350px;
  padding: 10px 0;
  max-height: 300px;
  pre {
    color: #ccc;
  }
}
.prism-editor__container {
  height: 100%;
  min-height: 300vh !important;
}
.prism-editor__textarea:focus {
  outline: none;
}
.ql-editor {
  overflow-y: scroll;
  resize: vertical;
}
.quill-editor {
  .ql-toolbar,
  .ql-container {
    border-color: #e4e6ef;
  }
}

.input-group-append {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}
</style>
