import ApiService from "@/core/services/api.service";
var url = process.env.VUE_APP_API_ADMIN;
ApiService.setHeader();

class AdminWidgets {
  getAll() {
    let options = "?page=1&perPage=99";
    return ApiService.get(url, "widgets" + options);
  }
}
export default new AdminWidgets();
