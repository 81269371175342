<template>
  <div>
    <div class="grid-stack">
      <div
        v-for="(widget, i) in widgets"
        :key="i"
        class="grid-stack-item"
        :data-gs-id="widget.id"
        :data-gs-x="widget.x"
        :data-gs-y="widget.y"
        :data-gs-width="widget.width"
        :data-gs-height="widget.height"
      >
        <BaseWidget :data="widget" />
      </div>
    </div>
  </div>
</template>

<script>
import { GridStack } from "gridstack";
import { bus } from "@/main";
import BaseWidget from "@/components/Admins/Welcome/Widgets/BaseWidget";

export default {
  components: { BaseWidget },
  props: {
    widgets: {
      type: Array,
      default: () => []
    },
    disable: Boolean
  },
  data() {
    return {
      gridStack: null
    };
  },
  mounted() {
    this.loadGridStack();
  },
  beforeDestroy() {
    this.removeGridStack();
  },
  methods: {
    loadGridStack() {
      const options = {
        cellHeight: 80,
        margin: 10
      };
      this.gridStack = GridStack.init(options);

      let me = this;
      this.gridStack.on("dragstop", function (e, el) {
        me.emitUpdate(e, el);
      });
      this.gridStack.on("resizestop", function (e, el) {
        me.emitUpdate(e, el);
      });

      if (this.disable) {
        this.gridStack.disable();
      }
    },
    emitUpdate(e, el) {
      let id = el.dataset.gsId;
      bus.$emit("updateWidget", { id: parseInt(id) });
    },
    removeGridStack() {
      if (!this.gridStack) return;
      this.gridStack.destroy(true);
    }
  }
};
</script>
