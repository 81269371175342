<template>
  <div class="h-100 card card-custom overflow-auto">
    <div class="card-header align-items-center">
      <div class="card-title">
        {{ $t("widgets.configuration") }}
        {{ $t(title) }}
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary mr-1" @click="removeWidget">
          {{ $t("widgets.delete") }}
        </button>
        <button class="btn btn-secondary mr-1" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
        <button
          class="btn btn-primary"
          :class="{ 'spinner spinner-left': isUpdating }"
          @click="updateWidget"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <div class="card-body">
      <div v-for="(field, i) in config" :key="i" class="mb-1">
        <div class="form-group">
          <FieldRender
            :field="field"
            :snippet-prefix="snippetPrefix"
            :disabled="isUpdating"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldRender from "@/components/Tools/FieldRender";
import { bus } from "@/main";

export default {
  components: { FieldRender },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isUpdating: false
    };
  },
  computed: {
    snippetPrefix: function () {
      return "widgets." + this.data.name;
    },
    title: function () {
      return "widgets." + this.data.name + "." + this.data.name;
    }
  },
  mounted() {
    bus.$on("updateWidgetDone", this.handleFinish);
  },
  destroyed() {
    bus.$off("updateWidgetDone", this.handleFinish);
  },
  methods: {
    updateWidget() {
      this.isUpdating = true;
      bus.$emit("updateWidget", { id: this.data.id });
    },
    handleFinish(payload) {
      if (payload.id !== this.data.id) {
        return;
      }
      this.isUpdating = false;
      if (payload.success) {
        this.$emit("close");
      }
    },
    removeWidget() {
      bus.$emit("removeWidget", { id: this.data.id });
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
