<template>
  <div class="grid-stack-item-content">
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <component
      :is="currentComponent"
      v-if="!isConfig && currentComponent"
      :key="componentKey"
      :data="data.data"
      :config="data.config"
      class="h-100"
      @load-data="loadData"
    />
    <BaseConfig
      v-else-if="isConfig"
      :data="data"
      :config="data.config"
      @close="closeConfig"
      @cancel="applyOldConfig"
    />
    <div v-if="!isConfig" class="config-button" @click="showConfig">
      <i class="fal fa-gear icon-lg text-hover-primary" />
    </div>
  </div>
</template>

<script>
import BaseConfig from "@/components/Admins/Welcome/Widgets/BaseConfig";
import AdminWidgetConfig from "@/components/Admins/Welcome/adminWidgetConfig";
export default {
  components: { BaseConfig },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isConfig: false,
      componentKey: 1,
      isBusy: false,
      configBackup: "",
      widgetsFiles: {
        welcome: () =>
          import("@/components/Admins/Welcome/Widgets/Welcome/Widget"),
        workflowReporting: () =>
          import("@/components/Admins/Welcome/Widgets/WorkflowReporting/Widget")
      }
    };
  },
  computed: {
    currentComponent: function () {
      return this.data.name ? this.widgetsFiles[this.data.name] : false;
    }
  },
  mounted() {},
  methods: {
    loadData(config) {
      this.isBusy = true;
      const params = {
        id: this.data.id,
        config: config
      };
      AdminWidgetConfig.getData(params)
        .then(response => {
          this.data.data = response.data;
          this.componentKey++;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    closeConfig() {
      this.isConfig = false;
      this.componentKey++;
    },
    applyOldConfig() {
      this.data.config = JSON.parse(this.configBackup);
      this.isConfig = false;
      this.componentKey++;
    },
    showConfig() {
      this.configBackup = JSON.stringify(this.data.config);
      this.isConfig = true;
    }
  }
};
</script>

<style lang="scss">
.config-button {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  .grid-stack-item-content:hover & {
    display: block;
  }
}
</style>
