<template>
  <component :is="currentComponent" v-if="currentComponent" />
</template>

<script>
export default {
  components: {},
  props: ["data"],
  data() {
    return {
      widgetsFiles: {
        workflowReporting: () =>
          import(
            "@/components/Admins/Welcome/Widgets/WorkflowReporting/Preview"
          )
      }
    };
  },
  computed: {
    currentComponent: function () {
      return this.data.name ? this.widgetsFiles[this.data.name] : false;
    }
  },
  mounted() {},
  methods: {}
};
</script>
